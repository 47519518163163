.content-board {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;

  &__wrapper {
    padding: 0 16px;
    padding-top: 36px;
    width: 100%;

    @mixin desktop {
      margin: 0 auto;
    }

    @mixin desktop_small {
      max-width: 620px;
    }

    @mixin desktop_big {
      max-width: 880px;
    }

    @mixin hd {
      max-width: 880px;
    }
  }
}

@media print {
  .content-board {
    overflow: visible;
  }
}

.content-board__controls {
  position: sticky;
  z-index: var(--zindex-sticky);
  top: 0;
  left: 0;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 0;
  background-color: var(--other_white);

  @mixin mobile {
    padding: 12px 0;
    width: 100%;
  }

  & button:not(:last-of-type) {
    margin-right: 12px;
  }
}

.content-board__creation-buttons {
  display: flex;
}

.content-board__table {
  padding-top: 12px;
  padding-bottom: 88px;
  overflow: auto;
  width: 100%;

  @mixin not_mobile {
    padding-top: 32px;
  }

  @mixin tablet {
    padding-bottom: 40px;
  }

  @mixin desktop {
    padding-bottom: 48px;
  }
}

.browser__support-error {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 15px 55px;
  width: 500px;
  font-weight: bold;
  line-height: 1.25em;
  word-break: break;
  hyphens: auto;
  color: rgb(235 235 235);
  background: rgb(223 98 26);
  transform: translate(-50%, 120px);
}

.panel {
  position: fixed;
  z-index: calc(var(--zindex-fixed) - 1);
  left: 16px;
  bottom: 8px;
  padding: 8px 16px;
  display: flex;
  width: calc(100% - 32px);
  height: 60px;
  min-height: 60px;
  background-color: var(--other_light_grey);
  border-radius: 12px;
  align-items: center;
  gap: 16px;

  @media print {
    display: none;
  }
}

.panel__right-button {
  margin-left: auto;
}

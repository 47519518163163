@mixin desktop {
  .tool-item {
    visibility: hidden;
  }

  .row:hover .tool-item,
  .tool-item_active {
    visibility: visible;
  }
}

.tool-item_with-title {
  color: var(--other_dark_grey);
}

.tool-item_with-title_active {
  color: var(--textator_primary);
}

.tool-item_is-hidden_active {
  color: var(--other_dark_grey_05);
}

.complete-button {
  position: absolute;
  top: 0;
  padding: 2px;
  border: 1px solid var(--other_success);
  border-radius: 8px;
}

@media print {
  display: none;
}

.complete-button_desktop-only {
  @mixin desktop {
    position: absolute;
    left: 10px;
  }

  @mixin not_desktop {
    display: none;
  }
}

.complete-button_not-desktop {
  @mixin desktop {
    display: none;
  }

  @mixin not_desktop {
    position: fixed;
    z-index: var(--zindex-fixed);
    top: auto;
    right: 10px;
    bottom: 70px;
    width: 50px;
    height: 50px;
    border-radius: 4px;
  }
}

.textarea {
  padding: 0 10px;
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  resize: none;
  border: none;

  &:focus {
    outline: none;
  }
}

.editor-label-wrapper {
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.write-board {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  background-color: var(--other_white);

  @mixin desktop {
    align-items: center;
  }
}

.write-board__content {
  margin: 0;
  padding: 64px 32px 200px;
  overflow: auto;
  box-sizing: border-box;
  width: 100%;
  font-family: 'Lora', serif;
  font-size: 16px;
  background-color: transparent;
  border: none;
  flex-grow: 1;

  @mixin mobile {
    padding: 16px 4px 160px;
  }

  @mixin desktop_small {
    max-width: 620px;
  }

  @mixin desktop_big {
    max-width: 880px;
  }

  @mixin hd {
    max-width: 880px;
  }
}

.preview {
  position: relative;
  padding: 24px 32px;
}

.preview::after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  content: '';
  background-color: var(--other_dark_grey_01);
}

.preview__header {
  margin-bottom: 24px;
}

.preview__title-wrapper {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.preview__title {
  margin-right: auto;

  padding-right: 8px;
  display: -webkit-box;
  overflow: hidden;

  width: 100%;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 150%; /* 24px */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.preview__update {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 150%; /* 21px */
  color: var(--textator_primary);
}

.preview__subtitle {
  display: flex;
  align-items: center;
}

.preview__text {
  margin-right: 8px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 150%; /* 21px */
}

.preview__actions-group {
  display: flex;

  @mixin mobile {
    flex-direction: column;
    gap: 12px;
  }
}

.preview__action {
  border-radius: 24px;

  &:first-child {
    margin-right: 16px;
  }

  @mixin mobile {
    width: 100%;
  }
}

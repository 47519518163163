.before-actions {
  display: flex;
  align-items: center;
  gap: 24px;
}

.before-actions__breadcrumbs {
  display: flex;
  align-items: center;
  gap: 8px;
}

.window-tab {
  padding: 0 12px;
  display: flex;
  width: 25vw;
  max-width: 300px;
  height: 30px;
  background-color: var(--other_white);
  border-top: 1px solid var(--textator_primary_05);
  border-left: 1px solid var(--textator_primary_05);
  border-right: 1px solid var(--textator_primary_05);
  border-radius: 12px 12px 0 0;
  align-items: center;
}

.window-tab__name {
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--other_dark_grey);
}

.window-tab__button {
  margin-left: 5px;
}

.window-tab__button:first-of-type {
  margin-left: auto;
}

.window-tab-panel {
  position: absolute;
  z-index: var(--zindex-modal);
  left: 0;
  bottom: 0;
  display: flex;
}

.window-tab-panel__tab {
  margin-left: 5px;
}

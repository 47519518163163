.previews {
  padding: 32px 0;
  width: 100%;
  height: 100%;
}

.previews__header {
  position: relative;
  padding: 0 32px;
}

.previews__title {
  margin: 0;
  margin-bottom: 24px;
}

.previews__close {
  position: absolute;
  top: 0;
  right: 32px;
}

.previews__new {
  margin-bottom: 28px;
}

.previews__filter {
  margin-bottom: 36px;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 150%; /* 24px */
}

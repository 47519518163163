.error-screen {
  position: absolute;
  inset: 0;
  padding-top: 20px;
  padding-left: 10px;
  color: rgb(76 97 97);
  background-color: yellow;
}

@media (hover: hover) and (pointer: fine) {
  .container {
    padding-top: 100px;
    padding-left: 100px;
  }
}

.error-screen h1,
.error-screen h2,
.error-screen h3 {
  color: rgb(76 97 97);
}

pre {
  font-size: 16px;
  color: rgb(76 97 97);
}

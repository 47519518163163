.card {
  position: relative;
  margin-bottom: 8px;
  padding: 8px 16px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  min-height: 92px;
  border: 1px solid var(--other_dark_grey_02);
  border-radius: 8px;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }

  @mixin mobile {
    text-decoration: none;
    color: unset;
  }

  @mixin not_mobile {
    margin-bottom: 16px;
    padding: 12px 16px;
    flex-direction: row;
  }
}

.card__header {
  display: flex;
  align-content: center;

  @mixin not_mobile {
    flex-grow: 1;
    padding: 16px;
  }
}

.card__before-actions {
  @mixin not_mobile {
    padding-right: 32px;
    display: flex;
    align-items: center;
  }
}

.card__after-actions {
  margin-left: auto;
  display: flex;
  gap: 8px;

  li {
    padding: 12px;
  }

  @mixin not_mobile {
    align-items: center;
  }
}

.card__title {
  margin-bottom: 8px;
  display: flex;
  width: 100%;
  text-align: left;
  text-decoration: none;
  color: inherit;
  align-items: center;

  span {
    display: -webkit-box;
    overflow: hidden;
    font-size: 16px;
    line-height: 24px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  @mixin not_mobile {
    margin-bottom: 0;
  }

  @mixin hover {
    cursor: pointer;
  }
}

.card__title-icon {
  position: absolute;
  left: 32px;
  bottom: 4px;
  display: flex;
  color: var(--other_dark_grey);
  opacity: 0.1;
  transform: scale(2.75);

  @mixin not_mobile {
    position: static;
    left: unset;
    bottom: unset;
    margin-right: 12px;

    display: flex;
    opacity: unset;
    transform: unset;
    align-items: center;
  }
}

.button {
  background: transparent;
}

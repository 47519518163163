.split-wrapper {
  display: flex;
  width: 100%;
}

.split-wrapper__children {
  display: flex;
  width: 100%;
}

.split-wrapper__secondary {
  margin-left: 8px;
  width: 100%;
}

.window-wrapper {
  position: absolute;
  z-index: var(--zindex-modal);
  background-color: transparent;
  border-radius: 12px;
  box-shadow: -2px 4px 10px rgba(46, 45, 90, 0.05);
}

.window {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--other_light_grey);
  border: 1px solid var(--textator_primary_05);
  border-radius: 12px;
}

.window__header {
  padding: 0 12px;
  display: flex;
  width: 100%;
  min-height: 30px;
  align-items: center;
  cursor: move;
  color: var(--other_dark_grey);
}

.window__header-title {
  overflow: hidden;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--other_dark_grey);
}

.window__button {
  margin-left: 5px;
}

.window__button:first-of-type {
  margin-left: auto;
}

.window__collapse-icon {
  position: relative;
  width: 20px;
  height: 20px;

  &::after {
    position: absolute;
    top: 9px;
    left: 5px;
    width: 10px;
    height: 2px;
    content: '';
    background-color: var(--other_dark_grey);
    border-radius: 2px;
    opacity: 0.8;
  }
}

.window__content {
  position: relative;
  left: -1px;
  padding: 12px 2px;
  overflow: auto;
  width: calc(100% + 2px);
  font-size: 16px;
  line-height: 25px;
  cursor: default;
  color: var(--other_dark_grey);
  background-color: white;
  border-top: 1px solid var(--textator_primary_05);
  border-left: 1px solid var(--textator_primary_05);
  border-right: 1px solid var(--textator_primary_05);
  border-radius: 12px;
  flex-grow: 1;
}

.edit-button {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 2px;
  border: 1px solid var(--textator_primary);
  border-radius: 8px;

  @media print {
    display: none;
  }

  @mixin mobile {
    right: 10px;
    left: auto;
    width: 50px;
    height: 50px;
  }
}

.page {
  overflow: hidden;
  width: 100vw;
  height: 100dvh;

  @media print {
    overflow: auto;
    height: unset;
  }
}

.project-board-shadow {
  display: flex;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background: var(--other_white);
  border-radius: 4px 0 0 0;
  box-shadow: -2px 4px 10px 0 var(--other_dark_grey_006);
}

.project-board {
  position: relative;
  margin: 0 auto;
  padding: 0 24px 64px;
  display: flex;
  flex-direction: column;

  width: 100%;

  @mixin mobile {
    padding-bottom: 88px;
  }

  @mixin not_mobile {
    padding-bottom: 24px;
  }

  @mixin desktop {
    margin: 0 auto;
  }

  @mixin desktop_small {
    width: 920px;
  }

  @mixin desktop_big {
    width: 1040px;
  }

  @mixin hd {
    width: 1200px;
  }
}

.header {
  position: sticky;
  z-index: var(--zindex-sticky);
  top: 0;
  left: 0;
  margin-bottom: 16px;
  padding-top: 32px;
  padding-bottom: 16px;
  display: flex;
  background-color: var(--other_white);
  flex-wrap: wrap;
  align-items: center;

  @mixin not_mobile {
    padding-top: 84px;
  }

  &__title {
    margin: 0;
    margin-bottom: 16px;
    font-size: 24px;

    @mixin not_mobile {
      margin-bottom: 0;
      font-size: 36px;
      font-weight: 700;
      line-height: 36px;
    }
  }

  &__controls {
    margin-left: auto;
    display: flex;
    width: auto;
    align-items: center;
    gap: 12px;

    &__control {
      width: auto;
    }
  }
}

.project-board__controls {
  margin: 20px auto;
  display: flex;
  width: 506px;
  justify-content: space-between;

  @mixin mobile {
    width: 100%;
    justify-content: space-around;
  }

  & .controll {
    &:first-child {
      margin-right: 10px;
    }
  }
}

.container {
  margin: 50px;
  padding: 16px;
  width: 364px;
  min-width: 400px;
  min-height: 400px;
  background-color: #f1f2f4;
}

.greeting {
  color: green;
}

.conflicts {
  display: flex;
  flex-direction: column;
}

.resolve-button {
  align-self: flex-start;
  opacity: 0.4;
}

.resolve-button_ready {
  opacity: 1;
}

.conflict-list {
  width: 1000px;
}

.conflict {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.conflict-type {
  margin: 10px auto;
  align-self: center;
}

.conflict__variants {
  display: flex;
  justify-content: center;
}

.conflict__resolve-variant {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.conflict__resolve-variant_rejected {
  opacity: 0.4;
}

.variant__label {
  margin-bottom: 10px;
}

.text-frame {
  margin: 0 auto;
  padding: 16px;
  padding-bottom: 36px;
  overflow: auto;
  width: 824px;
  height: 496px;
  border: 1px solid black;
}

.inserted-elem {
  text-decoration: none;
  color: var(--textator_primary);
}

.deleted-elem {
  text-decoration: line-through;
  color: var(--other_dark_grey_05);
}

.index-page {
  display: flex;
  flex-direction: column;

  @mixin not_mobile {
    flex-direction: row;
  }
}

.project-button__wrapper {
  padding: 8px 12px;
  background-color: var(--other_dark_grey_006);
  border-radius: 8px;
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;

  @mixin not_mobile {
    border: 1px solid var(--other_dark_grey_02);
    border-radius: 8px;
  }
}

.placeholder__emoji {
  margin-bottom: 8px;
  font-family:
    'Inter',
    'Helvetica',
    'Arial',
    -apple-system,
    blinkmacsystemfont,
    sans-serif;
  font-size: 36px;
  font-weight: 400;
  font-style: normal;
  line-height: 150%; /* 24px */
  text-align: center;
}

.placeholder__title {
  margin: 0;
  margin-bottom: 8px;
  font-family:
    'Inter',
    'Helvetica',
    'Arial',
    -apple-system,
    blinkmacsystemfont,
    sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  line-height: 150%; /* 30px */
  text-align: center;
  color: var(--other_main_text);
}

.placeholder__description {
  font-family:
    'Inter',
    'Helvetica',
    'Arial',
    -apple-system,
    blinkmacsystemfont,
    sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 150%; /* 24px */
  text-align: center;
  color: var(--other_dark_grey);
}

.placeholder__create-button {
  margin-top: 28px;
}

.board-wrapper {
  position: relative;
  margin: 0 auto;
  display: flex;
  overflow: auto;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: calc(100dvh - 56px);
  background-color: var(--other_white);
  border-radius: 8px 0 0 0;
  box-shadow: -2px 4px 10px rgba(46, 45, 90, 0.05);
  flex-grow: 1;
  align-items: center;

  @media print {
    height: unset;
  }

  @mixin not_mobile {
    align-items: center;
  }

  @mixin mobile {
    width: 100%;
    height: calc(100dvh - 45px);
  }
}

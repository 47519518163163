.compiler-template {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.compiler-template_view_mode {
  &:hover::before {
    position: absolute;
    top: 0;
    left: -5px;
    width: 5px;
    height: 100%;
    content: '';
    background-color: hsl(0deg 0% 76% / 50%);

    @mixin not_desktop {
      left: 0;
      background-color: hsl(0deg 0% 76% / 100%);
    }
  }
}

.compiler-template__content {
  width: 100%;
}

.compiler-template__content_hided {
  position: absolute;
  z-index: 0;
}

.compiler-template__editor {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
}

.compiler-template__editor_active {
  position: relative;
  width: 100%;
}

.compiler-template__editor_active::before {
  position: absolute;
  top: 0;
  left: -5px;
  width: 5px;
  height: 100%;
  content: '';
  background-color: var(--textator_primary);
  opacity: 0.2;

  @mixin not_desktop {
    left: 0;
    opacity: 0.5;
  }
}

.name-slot {
  position: sticky;
  z-index: var(--zindex-sticky);
  top: 0;
  left: 0;
  padding: 0;
  width: 100%;
  height: 0;
}

.edit-button-slot {
  position: sticky;
  z-index: var(--zindex-sticky);
  top: 32px;
  padding: 0;
  width: 0;
  height: 0;
}

.complete-button-slot {
  position: sticky;
  z-index: var(--zindex-sticky);
  top: 32px;
  padding: 0;
  width: 0;
  height: 0;

  @mixin not_desktop {
    position: unset;
  }
}

.header__logo-wrapper {
  margin-bottom: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.header__back {
  padding: 8px 12px;
}

.footer__auth-wrapper {
  padding: 8px 12px;
}

.preview-creator {
  display: flex;
  flex-direction: column;
}

.preview-creator__input {
  margin-bottom: 24px;
}

.preview-creator__settings {
  margin: 0 0 12px 0;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
}

.preview-creator__selections {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @mixin mobile_small {
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
  }
}

.preview-creator__selection-item {
  display: flex;
  width: 100%;
  align-items: center;
}

.preview-creator__selection-user {
  margin-right: auto;
  display: flex;

  font-size: 14px;
  color: var(--other_dark_grey);
  align-items: center;

  gap: 8px;
}

.preview-creator__selection-user-icon {
  padding: 6px;
  display: flex;
  border: 1px solid var(--other_grey);
  border-radius: 50%;
}

.preview-creator__buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.button:not(:first-of-type) {
  margin-left: 12px;
}

.note-name {
  position: absolute;
  top: 0;
  left: 0;
  padding: 4px 10px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  background: white;
  border: none;
  border-radius: 8px;

  &:hover {
    text-decoration: underline;
  }

  @media print {
    display: none;
  }

  @mixin mobile {
    right: 10px;
    left: auto;
    width: 50px;
    height: 50px;
  }
}
